<template>
  <div class="container-fluid  my-3">
    <div class="row">
      <b-col sm="3">
        <SidePage></SidePage>
      </b-col>
      <b-col sm="9">
        <b-container>
          <b-row>
            <div class="ProdList container">
              <b-skeleton-wrapper :loading="newestLayoutLoading">
                <template #loading>
                  <b-container fluid>
                    <landingLoader></landingLoader>
                  </b-container>
                </template>
                <b-row v-if="!newestLayoutLoading">
                  <b-col
                    sm="3"
                    cols="6"
                    class="px-1 my-1"
                    v-for="Product in itemsForList"
                    v-bind:key="Product.id"
                  >
                    <ProdCard
                      class="ProdByCatItem"
                      v-bind:Product="Product"
                    ></ProdCard>
                  </b-col>
                </b-row>
                <b-row align-h="center" v-if="!newestLayoutLoading">
                  <b-pagination
                    class="my-3 p-0"
                    v-model="currentPage"
                    :total-rows="NewestProducts.newest.items.length"
                    :per-page="perPage"
                    aria-controls="my-cards"
                  ></b-pagination>
                </b-row>
              </b-skeleton-wrapper>
            </div>
          </b-row>
        </b-container>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SidePage from "../components/widgets/SidePage.vue";
import ProdCard from "../components/items/ProdCard.vue";
import landingLoader from "../components/widgets/landingLoader.vue";

export default {
  name: "newest",
  components: {
    SidePage,
    ProdCard,
    landingLoader,
  },
  computed: {
    ...mapGetters(["Categories", "NewestProducts"]),
    itemsForList() {
      return this.NewestProducts.newest.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      newestLayoutLoading: true,
      waitReady: false,
    };
  },
  methods: {
    ...mapActions(["getNewest"]),
    getResults(page = 1) {
      var obj = {
        breakpoint: this.$mq,
        page: page,
      };
      //this.getLanding(obj);
      this.getNewest(obj);
    },
  },
  metaInfo: {
    title: "Newest Product",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "قم بتصفح أحدث المنتجات الموجودة لدى الشركة عبر الضغط على الرابط أعلاه",
      },
    ],
  },
  mounted() {
    this.$gtag.event("E_NewProducts", { method: "Google" });

    this.getResults();
  },
  watch: {
    currentPage() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 300);
    },
    NewestProducts() {
      this.newestLayoutLoading = false;
    },
  },
};
</script>

<style></style>
